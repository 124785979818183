import React, { useRef } from 'react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react'
import { useThemePath } from '../hooks/use-theme-path';

const Outer = styled.div`
  .swiper-pagination {
    transform: scale(1.25);
  }
  --swiper-pagination-bullet-inactive-color: white;
  --swiper-pagination-bullet-inactive-opacity: 0.8;
  --swiper-pagination-color: gray;
`

const SlideItem = styled.div`
margin-bottom: 2rem;

`

const SlideImage = styled.img`
  width: 100vw;
  height: auto;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.2);
`

const Pager = styled.div``

export const TopPageSlider:FunctionComponent = () => {
  const themePath = useThemePath()

  return <Outer>
    <Swiper
      modules={[
        Autoplay,
        Pagination,
      ]}
      loop
      autoplay
      pagination={{clickable: true}}
    >
      <SwiperSlide>
        <SlideItem>
          <SlideImage src={`${themePath}/images/top_bnr1.jpg`} width="2370" height="1382" />
        </SlideItem>
      </SwiperSlide>
      <SwiperSlide>
        <SlideItem>
          <SlideImage src={`${themePath}/images/top_bnr2.jpg`} width="2370" height="1382" />
        </SlideItem>
      </SwiperSlide>
      <SwiperSlide>
        <SlideItem>
          <SlideImage src={`${themePath}/images/top_bnr3.jpg`} width="2370" height="1382" />
        </SlideItem>
      </SwiperSlide>
      <SwiperSlide>
        <SlideItem>
          <SlideImage src={`${themePath}/images/top_bnr4.jpg`} width="2370" height="1382" />
        </SlideItem>
      </SwiperSlide>
    </Swiper>
  </Outer>
}