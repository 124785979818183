import React from 'react';
import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import SvgCloseIcon from '../generated/svg/close-icon';
import SvgLogo from '../generated/svg/logo';
import SvgRightArrow from '../generated/svg/right-arrow';

const Outer = styled.div<{show: boolean}>`
  transform: translate(100%, -100%);
  display: flex;
  flex-direction: column;
  color: white;
  background-color: rgba(0,33,50,0.9);
  min-width: 20rem;
  transition: transform 0.4s;
  pointer-events: none;
  position: relative;
  ${({show}) => show && css`
    transform: translate(0, 0);
    pointer-events: initial; 
  `}
  @media (max-width: 50rem) {
    transition: none;
    min-width: none;
    width: 100vw;
    height: 100vh;
  }
`

const ButtonContainer = styled.button`
  border: 0 none;
  display: block;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  padding: 2.125rem;
  margin-left: 2rem;
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
  transition: opacity 0.4s;
  &:hover {
    opacity: 0.5;
  }
  svg {
    height: 1.75rem;
    width: 1.75rem;
  }
  @media (max-width: 50rem) {
    padding: 1rem;
    align-self: stretch;
  }
`
  
const LinkContainer = styled.div``

const LinkOuter = styled.a`
  display: flex;
  padding: 1rem 2rem 1rem 3rem;
  justify-content: space-between;
  & + & {
    border-top: 1px solid white;
  }
  &:hover {
    background-color: rgba(0,33,50,0.8);
  }
`

const LinkLabel = styled.div`

`

const ArrowContainer = styled.div`
  svg {
    height: 1rem;
    width: auto;
  }
`

const LogoContainer = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  margin: 1rem;
  pointer-events: none;
  @media (max-width: 50rem) {
    display: block;
  }
  svg {
    height: 2.5rem;
  }
`

type LinkProps = {
  label: string,
  href: string
}
const Link:FunctionComponent<LinkProps> = ({href, label}) => {
  return <LinkOuter href={href}>
    <LinkLabel>{label}</LinkLabel>
    <ArrowContainer>
      <SvgRightArrow />
    </ArrowContainer>
  </LinkOuter>
}

type MenuProps = {
  show: boolean;
  onClose: () => void
}
export const Menu: FunctionComponent<MenuProps> = ({show, onClose}) => {
  return <Outer show={show}>
    <ButtonContainer onClick={() => onClose()}>
      <SvgCloseIcon />
    </ButtonContainer>
    <LinkContainer>
      <Link href="/recycle/" label="リサイクルショップ" />
      <Link href="/qa/" label="よくあるご質問" />
      <Link href="/jirei/" label="回収事例" />
      <Link href="/purchase/" label="各種買取" />
      <Link href="/unwanted/" label="家電回収" />
      <Link href="/left/" label="家具回収" />
      <Link href="/office/" label="オフィス回収" />
      <Link href="/sodaigomi/" label="粗大ゴミ回収" />
      <Link href="/moving/" label="引越し処分" />
      <Link href="/gomiyasiki/" label="ゴミ屋敷清掃" />
      <Link href="/ihinseiri/" label="遺品整理・買取" />
      <Link href="/saitama/" label="対応エリアについて" />
      <Link href="/privacy/" label="プライバシーポリシー" />
      <Link href="/blog/" label="BLOG" />
      <Link href="/compensation/" label="賠償保障制度" />
      <Link href="/company/" label="会社概要" />
      <Link href="/sitemap/" label="サイトマップ" />
    </LinkContainer>
    <LogoContainer>
      <SvgLogo />
    </LogoContainer>
  </Outer>
}