import * as React from "react";

function SvgLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 131.588 28.712"
      {...props}
    >
      <defs>
        <linearGradient
          id="logo_svg__a"
          x1={7.28}
          y1={1.901}
          x2={19.245}
          y2={25.383}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#c0e4f9" />
          <stop offset={0.486} stopColor="#009fe8" />
          <stop offset={0.834} stopColor="#0080c0" />
        </linearGradient>
        <linearGradient
          id="logo_svg__b"
          x1={18.865}
          y1={4.569}
          x2={39.333}
          y2={21.143}
          xlinkHref="#logo_svg__a"
        />
      </defs>
      <path
        d="M13.706 26.031c.279 0 .558-.01.84-.028a12.623 12.623 0 0011.77-13.421s-2.889 1.272-6.506 5.394a8.202 8.202 0 01-5.592 3.029q-.254.017-.506.017a7.615 7.615 0 01-7.59-7.118 7.847 7.847 0 017.1-8.094 2.281 2.281 0 002.058-2.586 2.283 2.283 0 00-2.365-2.102 7.418 7.418 0 00-.677-.026 8.538 8.538 0 00-2.553.347 12.625 12.625 0 004.021 24.588z"
        fill="url(#logo_svg__a)"
      />
      <path
        d="M27.41 12.51a1.096 1.096 0 00-1.535-.931c-.127.055-3.155 1.418-6.89 5.674a7.039 7.039 0 01-4.839 2.658c-.145.01-.29.014-.434.014a6.528 6.528 0 01-6.496-6.092 6.779 6.779 0 016.115-6.932 3.301 3.301 0 002.16-1.061 3.58 3.58 0 00.883-2.688C16.258 1.378 15.038.24 13.028.032a8.382 8.382 0 00-.79-.032 9.734 9.734 0 00-2.903.404 13.722 13.722 0 004.371 26.723c.303 0 .609-.01.91-.03A13.72 13.72 0 0027.41 12.51zM9.685 1.443a8.538 8.538 0 012.553-.347c.32 0 .563.014.677.026a2.283 2.283 0 012.365 2.102 2.281 2.281 0 01-2.058 2.586 7.847 7.847 0 00-7.1 8.095 7.615 7.615 0 007.59 7.117q.252 0 .506-.017a8.202 8.202 0 005.592-3.03c3.617-4.121 6.506-5.394 6.506-5.394a12.623 12.623 0 01-11.77 13.422c-.282.019-.561.028-.84.028a12.625 12.625 0 01-4.02-24.588z"
        fill="#9bd7f5"
      />
      <path
        d="M40.747 13.197A12.626 12.626 0 0028.162 1.4q-.416 0-.838.027a12.625 12.625 0 00-11.771 13.422s2.891-1.271 6.507-5.394a8.21 8.21 0 015.592-3.03q.254-.016.504-.016a7.615 7.615 0 017.591 7.117 7.846 7.846 0 01-7.099 8.095 2.353 2.353 0 00.306 4.689c.114.011.356.025.673.025a8.564 8.564 0 002.558-.347 12.629 12.629 0 008.562-12.79z"
        fill="url(#logo_svg__b)"
      />
      <path
        d="M41.841 13.126A13.748 13.748 0 0028.162.303c-.301 0-.607.01-.909.03A13.736 13.736 0 0014.46 14.919a1.096 1.096 0 001.535.932c.127-.056 3.156-1.418 6.89-5.674a7.043 7.043 0 014.838-2.658c.145-.01.29-.015.434-.015a6.53 6.53 0 016.497 6.093 6.778 6.778 0 01-6.115 6.932 3.305 3.305 0 00-2.16 1.06 3.583 3.583 0 00-.883 2.689c.115 1.772 1.335 2.91 3.345 3.12.192.02.485.032.786.032a9.514 9.514 0 002.909-.405 13.73 13.73 0 009.305-13.899zM29.627 26.334c-.317 0-.56-.014-.673-.025a2.353 2.353 0 01-.306-4.69 7.846 7.846 0 007.1-8.094 7.615 7.615 0 00-7.592-7.117q-.25 0-.504.016a8.21 8.21 0 00-5.592 3.03c-3.615 4.123-6.507 5.394-6.507 5.394A12.625 12.625 0 0127.324 1.426q.422-.027.838-.027a12.625 12.625 0 014.023 24.588 8.564 8.564 0 01-2.558.347z"
        fill="#9bd7f5"
      />
      <g>
        <path
          d="M54.919 21.002a2.31 2.31 0 001.876.827 5.436 5.436 0 003.33-1.34l1.833 2.681a9.103 9.103 0 01-5.23 1.788 6.266 6.266 0 01-6.19-5.967 6.208 6.208 0 016.034-6.28 6.461 6.461 0 015.743 3.531zm3.218-4.916a2.066 2.066 0 00-1.543-.47 2.877 2.877 0 00-2.704 2.905v.403z"
          fill="#0087d1"
        />
        <path
          d="M71.566 16.644a3.495 3.495 0 00-2.257-.804 2.79 2.79 0 00-2.86 2.771 2.958 2.958 0 002.927 3.218 3.18 3.18 0 002.213-.827l1.9 2.48a6.455 6.455 0 01-4.18 1.476 5.984 5.984 0 01-6.346-5.922 6.047 6.047 0 016.347-6.325 6.56 6.56 0 014.067 1.408z"
          fill="#84cff3"
        />
        <path
          d="M80.504 15.951a2.883 2.883 0 102.928 2.883 2.924 2.924 0 00-2.928-2.883z"
          fill="#e63687"
        />
        <path
          d="M80.504 12.712a6.248 6.248 0 00-6.392 6.123 6.41 6.41 0 0012.806 0 6.253 6.253 0 00-6.414-6.123zm0 9.005a2.883 2.883 0 112.928-2.883 2.923 2.923 0 01-2.928 2.883zM89.33 21.002a3.461 3.461 0 002.525 1.05c.581 0 1.565-.09 1.565-.916 0-1.587-5.363 0-5.363-4.67 0-2.57 2.078-3.755 4.357-3.755a5.85 5.85 0 013.822 1.609l-1.922 1.944a3.148 3.148 0 00-1.833-.648c-.425 0-1.184.09-1.184.693 0 1.922 5.363 0 5.363 4.783 0 2.525-2.257 3.866-4.514 3.866a6.48 6.48 0 01-4.581-1.565zM97.553 16.13l.737-2.905h1.095v-2.28l3.486-1.34v3.62h1.99v2.905h-1.99v4.023c0 1.14.872 1.453 1.856 1.453a3.184 3.184 0 00.916-.134l-1.006 3.441a4.05 4.05 0 01-.535.045c-3.085 0-4.716-1.475-4.716-4.537V16.13zM110.782 21.002a2.312 2.312 0 001.877.827 5.432 5.432 0 003.33-1.34l1.832 2.68a9.098 9.098 0 01-5.23 1.789 6.265 6.265 0 01-6.19-5.967 6.208 6.208 0 016.033-6.28 6.462 6.462 0 015.744 3.531zm3.217-4.916a2.064 2.064 0 00-1.542-.47 2.877 2.877 0 00-2.703 2.905v.402z"
          fill="#84cff3"
        />
        <g>
          <path
            d="M125.262 15.84a2.999 2.999 0 102.839 2.994 2.978 2.978 0 00-2.84-2.994z"
            fill="none"
          />
          <path
            d="M125.91 12.712a3.906 3.906 0 00-3.487 2.1h-.044v-2.1l-3.263.357v15.643l3.486-.357v-4.716h.044a3.484 3.484 0 003.085 1.319 6.115 6.115 0 005.857-5.99c0-3.173-2.324-6.256-5.678-6.256z"
            fill="#84cff3"
          />
          <path
            d="M125.262 21.829a2.999 2.999 0 112.839-2.994 2.978 2.978 0 01-2.84 2.994z"
            fill="#e63687"
          />
        </g>
      </g>
      <g fill="#0087d1">
        <path d="M58.665 1.861v.73H55.95V7.02h3.184v.74h-7.142v-.74h3.143V2.592h-2.663v-.73zM72.068 1.895v5.848H65.86v-.731h5.394V2.626H65.91v-.731zM83.433 7.676a9.16 9.16 0 005.016-5.387H83.87v-.722h5.613a9.705 9.705 0 01-1.664 3.504 35.697 35.697 0 012.924 2.596l-.622.622a26.844 26.844 0 00-2.756-2.647 9.435 9.435 0 01-3.47 2.655zM104.03 3.642v.731h-2.857c-.084 2.386-.966 3.554-3.403 4.042l-.336-.656c2.126-.353 2.899-1.344 2.932-3.386h-3.352v-.73zm-.698-2.201v.723h-5.62V1.44zM111.88 5.49a7.12 7.12 0 00-.672-2.016l.664-.185a8.282 8.282 0 01.723 2.025zm.01 2.32c2.688-.58 3.73-1.706 4.225-4.706l.74.127c-.487 2.411-1.202 4.41-4.672 5.2zm1.805-2.664a8.172 8.172 0 00-.554-1.99l.672-.16a8.388 8.388 0 01.588 1.982zM124.764 7.533c3.689-.547 4.664-2.597 4.966-5.017h-5.714v-.714h5.705a.692.692 0 01-.016-.15.874.874 0 11.823.873c-.361 2.966-1.68 5.016-5.42 5.739zm5.37-5.882a.449.449 0 00.445.445.445.445 0 10-.446-.445z" />
      </g>
    </svg>
  );
}

export default SvgLogo;
