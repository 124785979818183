import React from 'react';
import { FunctionComponent } from 'react';
import { ResetCss } from './components/reset-css';
import { RootCss } from './components/root-css';
import { TopPage } from './pages/top-page';
import { ApolloClient, InMemoryCache, ApolloLink, ApolloProvider, HttpLink } from '@apollo/client'

const apolloClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://om-trust.jp/index.php?graphql',
    fetchOptions: {
      mode: 'cors'
    }
  }),
  cache: new InMemoryCache()
})

export const App: FunctionComponent = ({children}) => {
  return <ApolloProvider client={apolloClient}>
    <ResetCss />
    <RootCss />
    {children}
  </ApolloProvider>
}