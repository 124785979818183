import * as React from "react";

function SvgFreeTelIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 34.922 32.119"
      {...props}
    >
      <g fill="currentcolor">
        <path d="M0 25.814c.95.038 3.221.047 4.216 0l.448.434c-.315 2.892-1.404 4.734-3.479 5.527l-.651-.87a4.48 4.48 0 003.225-4.13c-.897 0-2.796.022-3.752.085zM5.394 25.42l.815.013c-.037 1.097-.013 2.563.014 3.89l-.842.014c.025-1.32.032-2.837.013-3.918zm2.79-.025l.82.009c-.02 1.083-.025 2.27-.025 3.101a3.397 3.397 0 01-2.314 3.614l-.645-.932a2.326 2.326 0 002.175-2.658c.007-.816.01-1.974-.011-3.134zM9.64 27.981c1.223.022 3.737.015 5.03 0v1.027a114.215 114.215 0 00-5.03 0zM17.535 26.235c.596 0 1.383.013 2.08-.041l.373.442c-.778 2.755-1.83 4.392-4.364 5.33l-.598-.845a5.67 5.67 0 002.722-1.641 8.56 8.56 0 00-1.197-1.042l.524-.671a11.433 11.433 0 011.22.975 6.682 6.682 0 00.676-1.601c-.694 0-1.378 0-1.924.021a10.348 10.348 0 01-1.385 1.809l-.66-.685a6.788 6.788 0 002.055-3.104l.823.27c-.106.27-.22.53-.345.783zm1.403-.921l.345-.366a6.188 6.188 0 01.617.76l-.365.395a7.547 7.547 0 00-.597-.79zm.704-.26l.341-.352a6.252 6.252 0 01.611.731l-.353.39a5.154 5.154 0 00-.599-.77zM23.167 27.605c-.025 1.067-.007 2.95.013 4.324h-.852c.023-1.09.029-2.508.023-3.575a19.958 19.958 0 01-2.01 1.501l-.564-.892a11.125 11.125 0 004.245-3.866l.743.673a13.143 13.143 0 01-1.598 1.835z" />
        <path d="M26.714 27.835c.17 1.411.369 2.99.525 4.068l-.812.185c-.095-1.113-.269-2.689-.442-4.121-.594.109-1.144.226-1.498.331l-.161-1.033c.355-.047.917-.124 1.548-.216-.09-.702-.162-1.311-.226-1.735l.8-.154c.034.443.093 1.06.174 1.768.97-.153 1.985-.322 2.488-.428l.534.49a7.815 7.815 0 01-1.52 2.994l-.721-.625a5.586 5.586 0 001.284-1.868c-.475.085-1.217.206-1.973.344zM30.28 25.9l.802.039c-.03 3.154-.07 4.104-1.2 5.942l-.823-.607c1.157-1.365 1.326-2.449 1.22-5.375zm1.344-.474l.852.03c-.069 1.55-.053 3.202-.053 4.828a5.37 5.37 0 001.84-2.595l.57.716a7.369 7.369 0 01-2.67 3.34l-.545-.457c.038-.74.072-4.626.006-5.862zM8.644 7.69a17.143 17.143 0 012.843 2.147l.044.031a4.265 4.265 0 11-5.768-.012l.027-.02a17.124 17.124 0 012.854-2.145zm8.821 14.714h17.457V5.223a17.229 17.229 0 00-6.419 1.376 19.136 19.136 0 012.222 1.826l.01.014a6.32 6.32 0 11-8.732.007l.024-.021a19.652 19.652 0 012.224-1.808 17.348 17.348 0 00-6.776-1.396h.062a17.316 17.316 0 00-6.772 1.396 19.599 19.599 0 012.225 1.808l.024.022a6.318 6.318 0 11-8.738-.008l.012-.014A19.222 19.222 0 016.51 6.598 17.223 17.223 0 00.092 5.222v17.182h17.373zm8.909-14.713a17.068 17.068 0 00-2.848 2.146l-.043.031a4.269 4.269 0 105.768-.012l-.024-.02a17.228 17.228 0 00-2.854-2.145zm8.548-4.512V0H.092v3.179m.029.007a19.547 19.547 0 018.32 2.183 19.84 19.84 0 0118.133 0 19.535 19.535 0 018.32-2.183" />
      </g>
    </svg>
  );
}

export default SvgFreeTelIcon;
