import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { useBreakpoint } from '../hooks/use-breakpoint'
import { DesktopFooter } from './desktop-footer'
import { MobileFooter } from './mobile-footer'

const Outer = styled.div``


type FooterPrors = {

}
export const Footer: FunctionComponent<FooterPrors> = () => {
  const isMobile = useBreakpoint('50rem')
  console.log(isMobile)
  return <Outer>
    {
      isMobile ?
      <MobileFooter />:
      <DesktopFooter />
    }
  </Outer>
}