import React from 'react'
import { render } from 'react-dom'
import styled from 'styled-components'
import { App } from './app'
import { Footer } from './components/footer'
import { Header } from './components/header'
import { TopPage } from './pages/top-page'

const HeaderOuter = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
`
document.addEventListener('DOMContentLoaded', () => {
  const headerElement = document.getElementById('header-root')
  if(headerElement){
    render(<App><HeaderOuter><Header /></HeaderOuter></App>, headerElement)
  }

  const footerElement = document.getElementById('footer-root')
  if(footerElement){
    render(<App><Footer /></App>, footerElement)
  }

  const topPageElement = document.getElementById('top-page-root')
  if(topPageElement){
    render(<App><TopPage /></App>, topPageElement)
  }
})