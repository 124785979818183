import * as React from "react";

function SvgGradientSield(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 78 78"
      {...props}
    >
      <defs>
        <linearGradient
          id="gradient-sield_svg__a"
          x1={15.734}
          y1={8.471}
          x2={62.266}
          y2={55.002}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#c0e4f9" />
          <stop offset={0.486} stopColor="#009fe8" />
          <stop offset={0.834} stopColor="#0080c0" />
        </linearGradient>
        <linearGradient
          id="gradient-sield_svg__b"
          x1={20.375}
          y1={14.56}
          x2={57.625}
          y2={51.81}
          xlinkHref="#gradient-sield_svg__a"
        />
      </defs>
      <g opacity={0.5}>
        <path
          d="M68.5 15.55a2.095 2.095 0 00-2.133-2.062C51.914 12.289 43.526 6.698 39 2.148c-4.526 4.55-12.915 10.141-27.367 11.34A2.096 2.096 0 009.5 15.551a75.724 75.724 0 003.752 27.896 62.67 62.67 0 0010.385 18.94A55.05 55.05 0 0039 75.851a55.047 55.047 0 0015.362-13.466 62.673 62.673 0 0010.386-18.939A75.726 75.726 0 0068.5 15.551zM52.252 60.517A54.966 54.966 0 0139 72.568a54.968 54.968 0 01-13.252-12.052C19.145 52.246 11.52 37.9 12.291 16.258 25.457 15.081 33.873 10.388 39 5.988c5.127 4.4 13.543 9.093 26.709 10.27.771 21.643-6.854 35.988-13.457 44.258z"
          fill="url(#gradient-sield_svg__a)"
        />
        <path
          d="M60.908 18.577c-11.57-.96-18.285-5.435-21.908-9.078-3.623 3.643-10.338 8.118-21.908 9.078a1.678 1.678 0 00-1.707 1.651 60.617 60.617 0 003.003 22.332 50.171 50.171 0 008.314 15.161A44.068 44.068 0 0039 68.501a44.066 44.066 0 0012.298-10.78 50.17 50.17 0 008.314-15.161 60.621 60.621 0 003.003-22.332 1.678 1.678 0 00-1.707-1.651zm-5.421 11.518l-15.053 22a2.503 2.503 0 01-1.92 1.084 2.373 2.373 0 01-.143.004 2.501 2.501 0 01-1.889-.862l-11.28-13a2.5 2.5 0 113.777-3.277l9.158 10.554L51.36 27.27a2.5 2.5 0 114.127 2.825z"
          fill="url(#gradient-sield_svg__b)"
        />
      </g>
    </svg>
  );
}

export default SvgGradientSield;
