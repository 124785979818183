import * as React from "react";

function SvgBand(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 655.353 24.614"
      {...props}
    >
      <path
        d="M342.242 20.449s3.886-8.342 7.583-8.247 3.697 4.55 4.834 4.74 1.99-2.086 3.792-1.612 5.876 5.308 5.876 5.308z"
        fill="#417935"
      />
      <path
        fill="#d29100"
        d="M340.948 7.638V5.765h-4.104v1.873h-2.829V20.22h9.762V7.638h-2.829z"
      />
      <g fill="#fff">
        <path d="M335.993 9.256h2.111v2.111h-2.111zM339.688 9.256h2.111v2.111h-2.111zM335.993 12.599h2.111v2.111h-2.111zM339.688 12.599h2.111v2.111h-2.111zM335.993 15.941h2.111v2.111h-2.111zM339.688 15.941h2.111v2.111h-2.111z" />
      </g>
      <path fill="#e4001e" d="M253.205 19.649h7.447v4.965h-7.447z" />
      <path fill="#d6a600" d="M260.652 19.649h7.447v4.965h-7.447z" />
      <path fill="#00963b" d="M268.099 19.649h7.447v4.965h-7.447z" />
      <path fill="#c60f28" d="M275.546 19.649h7.447v4.965h-7.447z" />
      <path fill="#e73819" d="M282.993 19.649h7.447v4.965h-7.447z" />
      <path fill="#00a6d8" d="M290.44 19.649h7.447v4.965h-7.447z" />
      <path fill="#f9bc00" d="M297.888 19.649h7.447v4.965h-7.447z" />
      <path fill="#960b30" d="M305.335 19.649h7.447v4.965h-7.447z" />
      <path fill="#ec6a02" d="M312.782 19.649h7.447v4.965h-7.447z" />
      <path fill="#dc007a" d="M320.229 19.649h7.447v4.965h-7.447z" />
      <path fill="#f4a100" d="M327.676 19.649h7.447v4.965h-7.447z" />
      <path fill="#d29100" d="M335.124 19.649h7.447v4.965h-7.447z" />
      <path fill="#417935" d="M342.571 19.649h7.447v4.965h-7.447z" />
      <path fill="#0076ba" d="M350.018 19.649h7.447v4.965h-7.447z" />
      <path fill="#25a739" d="M357.465 19.649h7.447v4.965h-7.447z" />
      <path fill="#004c87" d="M364.913 19.649h7.447v4.965h-7.447z" />
      <path fill="#003067" d="M372.36 19.649h7.447v4.965h-7.447z" />
      <g>
        <path fill="#e4001e" d="M506.409 19.649h7.447v4.965h-7.447z" />
        <path fill="#d6a600" d="M513.856 19.649h7.447v4.965h-7.447z" />
        <path fill="#00963b" d="M521.303 19.649h7.447v4.965h-7.447z" />
        <path fill="#c60f28" d="M528.751 19.649h7.447v4.965h-7.447z" />
        <path fill="#e73819" d="M536.198 19.649h7.447v4.965h-7.447z" />
        <path fill="#00a6d8" d="M543.645 19.649h7.447v4.965h-7.447z" />
        <path fill="#f9bc00" d="M551.092 19.649h7.447v4.965h-7.447z" />
        <path fill="#960b30" d="M558.539 19.649h7.447v4.965h-7.447z" />
        <path fill="#ec6a02" d="M565.987 19.649h7.447v4.965h-7.447z" />
        <path fill="#dc007a" d="M573.434 19.649h7.447v4.965h-7.447z" />
        <path fill="#f4a100" d="M580.881 19.649h7.447v4.965h-7.447z" />
        <path fill="#d29100" d="M588.328 19.649h7.447v4.965h-7.447z" />
        <path fill="#417935" d="M595.775 19.649h7.447v4.965h-7.447z" />
        <path fill="#0076ba" d="M603.223 19.649h7.447v4.965h-7.447z" />
        <path fill="#25a739" d="M610.67 19.649h7.447v4.965h-7.447z" />
        <path fill="#004c87" d="M618.117 19.649h7.447v4.965h-7.447z" />
        <path fill="#003067" d="M625.564 19.649h7.447v4.965h-7.447z" />
      </g>
      <g>
        <path fill="#e4001e" d="M633.011 19.649h7.447v4.965h-7.447z" />
        <path fill="#d6a600" d="M640.459 19.649h7.447v4.965h-7.447z" />
        <path fill="#00963b" d="M647.906 19.649h7.447v4.965h-7.447z" />
      </g>
      <g>
        <path fill="#e4001e" d="M0 19.649h7.447v4.965H0z" />
        <path fill="#d6a600" d="M7.447 19.649h7.447v4.965H7.447z" />
        <path fill="#00963b" d="M14.894 19.649h7.447v4.965h-7.447z" />
        <path fill="#c60f28" d="M22.342 19.649h7.447v4.965h-7.447z" />
        <path fill="#e73819" d="M29.789 19.649h7.447v4.965h-7.447z" />
        <path fill="#00a6d8" d="M37.236 19.649h7.447v4.965h-7.447z" />
        <path fill="#f9bc00" d="M44.683 19.649h7.447v4.965h-7.447z" />
        <path fill="#960b30" d="M52.13 19.649h7.447v4.965H52.13z" />
        <path fill="#ec6a02" d="M59.578 19.649h7.447v4.965h-7.447z" />
        <path fill="#dc007a" d="M67.025 19.649h7.447v4.965h-7.447z" />
        <path fill="#f4a100" d="M74.472 19.649h7.447v4.965h-7.447z" />
        <path fill="#d29100" d="M81.919 19.649h7.447v4.965h-7.447z" />
        <path fill="#417935" d="M89.366 19.649h7.447v4.965h-7.447z" />
        <path fill="#0076ba" d="M96.814 19.649h7.447v4.965h-7.447z" />
        <path fill="#25a739" d="M104.261 19.649h7.447v4.965h-7.447z" />
        <path fill="#004c87" d="M111.708 19.649h7.447v4.965h-7.447z" />
        <path fill="#003067" d="M119.155 19.649h7.447v4.965h-7.447z" />
      </g>
      <g>
        <path fill="#e4001e" d="M126.602 19.649h7.447v4.965h-7.447z" />
        <path fill="#d6a600" d="M134.049 19.649h7.447v4.965h-7.447z" />
        <path fill="#00963b" d="M141.497 19.649h7.447v4.965h-7.447z" />
        <path fill="#c60f28" d="M148.944 19.649h7.447v4.965h-7.447z" />
        <path fill="#e73819" d="M156.391 19.649h7.447v4.965h-7.447z" />
        <path fill="#00a6d8" d="M163.838 19.649h7.447v4.965h-7.447z" />
        <path fill="#f9bc00" d="M171.285 19.649h7.447v4.965h-7.447z" />
        <path fill="#960b30" d="M178.733 19.649h7.447v4.965h-7.447z" />
        <path fill="#ec6a02" d="M186.18 19.649h7.447v4.965h-7.447z" />
        <path fill="#dc007a" d="M193.627 19.649h7.447v4.965h-7.447z" />
        <path fill="#f4a100" d="M201.074 19.649h7.447v4.965h-7.447z" />
        <path fill="#d29100" d="M208.521 19.649h7.447v4.965h-7.447z" />
        <path fill="#417935" d="M215.969 19.649h7.447v4.965h-7.447z" />
        <path fill="#0076ba" d="M223.416 19.649h7.447v4.965h-7.447z" />
        <path fill="#25a739" d="M230.863 19.649h7.447v4.965h-7.447z" />
        <path fill="#004c87" d="M238.31 19.649h7.447v4.965h-7.447z" />
        <path fill="#003067" d="M245.757 19.649h7.447v4.965h-7.447z" />
      </g>
      <g>
        <path fill="#e4001e" d="M379.807 19.649h7.447v4.965h-7.447z" />
        <path fill="#d6a600" d="M387.254 19.649h7.447v4.965h-7.447z" />
        <path fill="#00963b" d="M394.701 19.649h7.447v4.965h-7.447z" />
        <path fill="#c60f28" d="M402.148 19.649h7.447v4.965h-7.447z" />
        <path fill="#e73819" d="M409.596 19.649h7.447v4.965h-7.447z" />
        <path fill="#00a6d8" d="M417.043 19.649h7.447v4.965h-7.447z" />
        <path fill="#f9bc00" d="M424.49 19.649h7.447v4.965h-7.447z" />
        <path fill="#960b30" d="M431.937 19.649h7.447v4.965h-7.447z" />
        <path fill="#ec6a02" d="M439.384 19.649h7.447v4.965h-7.447z" />
        <path fill="#dc007a" d="M446.832 19.649h7.447v4.965h-7.447z" />
        <path fill="#f4a100" d="M454.279 19.649h7.447v4.965h-7.447z" />
        <path fill="#d29100" d="M461.726 19.649h7.447v4.965h-7.447z" />
        <path fill="#417935" d="M469.173 19.649h7.447v4.965h-7.447z" />
        <path fill="#0076ba" d="M476.62 19.649h7.447v4.965h-7.447z" />
        <path fill="#25a739" d="M484.068 19.649h7.447v4.965h-7.447z" />
        <path fill="#004c87" d="M491.515 19.649h7.447v4.965h-7.447z" />
        <path fill="#003067" d="M498.962 19.649h7.447v4.965h-7.447z" />
      </g>
      <path
        d="M263.232 10.166A2.758 2.758 0 00262 6.44a2.526 2.526 0 00-1.69-4 2.13 2.13 0 10-4.239-.309c0 .07.004.138.01.206a2.703 2.703 0 00-2.315 4.488 3.581 3.581 0 00-1.357 3.548 2.704 2.704 0 102.86 4.588 2.708 2.708 0 002.037 1.547l-.18 2.45-1.478 1.323 1.711-.35.661.855.778-.855 1.555.428-1.516-1.439-.115-2.569a2.713 2.713 0 001.572-1.66 2.704 2.704 0 102.938-4.525z"
        fill="#e4001e"
      />
      <path
        d="M272.122 16.175l-.082-.003c-.021-1.11-.598-3.446-5.243-2.724 0 0 .34 5.046 4.916 3.385l-.206 2.797-.97.867 1.123-.23.433.562.51-.561 1.02.28-.994-.944-.123-2.75c4.522 1.6 4.858-3.406 4.858-3.406-4.649-.723-5.222 1.619-5.242 2.727z"
        fill="#00963b"
      />
      <path
        fill="#e73819"
        d="M291.997 16.413l-4.594-5.185h3.629l-3.629-4.096h2.674l-3.948-4.456-3.949 4.456h2.674l-3.629 4.096h3.629l-4.594 5.185h4.994v3.993h1.847v-3.993h4.896z"
      />
      <path
        d="M301.4 14.335c0-1.943-3.52-7.918-3.52-7.918s-3.518 5.975-3.518 7.918a3.52 3.52 0 107.038 0z"
        fill="#00a6d8"
      />
      <g fill="#ec6a02">
        <path
          d="M313.605 11.736a2.606 2.606 0 10-2.606 2.607 2.606 2.606 0 002.606-2.607z"
          stroke="#ec6a02"
          strokeMiterlimit={10}
          strokeWidth={0.338}
        />
        <path d="M310.999 8.435a.657.657 0 01-.657-.657V6.074a.657.657 0 011.314 0v1.704a.657.657 0 01-.657.657zM313.848 9.588a.657.657 0 01-.464-1.121l1.205-1.205a.657.657 0 01.928.929l-1.205 1.204a.655.655 0 01-.464.193zM316.752 12.418h-1.704a.657.657 0 110-1.314h1.704a.657.657 0 110 1.314zM315.1 16.472a.655.655 0 01-.464-.192l-1.205-1.205a.657.657 0 01.928-.929l1.205 1.205a.657.657 0 01-.464 1.121zM311.065 18.17a.657.657 0 01-.657-.656V15.81a.657.657 0 111.314 0v1.704a.657.657 0 01-.657.657zM307.011 16.52a.657.657 0 01-.464-1.122l1.204-1.205a.657.657 0 01.93.929l-1.206 1.205a.654.654 0 01-.464.192zM307.016 12.484h-1.704a.657.657 0 010-1.313h1.704a.657.657 0 010 1.313zM308.169 9.635a.654.654 0 01-.465-.192L306.5 8.238a.657.657 0 11.929-.93l1.205 1.206a.657.657 0 01-.465 1.12z" />
      </g>
      <g fill="#004c87">
        <path d="M368.71 11.116a1.97 1.97 0 10-2.24 1.654 1.97 1.97 0 002.24-1.654z" />
        <path d="M367.705 10.549l7.163 5.034s-2.883 4.242-6.811 1.207c-3.046-2.354-.352-6.241-.352-6.241z" />
        <path d="M374.451 15.544l3.703.656-1.416 1.639-3.041-1.823.754-.472zM365.314 10.521l-1.706 1.403 2.135.156M371.017 17.011h.526v3.449h-.526zM370.088 17.011h.526v3.449h-.526zM369.057 13.095l5.759-5.07s1.673 8.016-5.759 5.07z" />
      </g>
      <g fill="#dc007a">
        <path d="M328.908 12.418a1.894 1.894 0 00-2.063-1.708l-.006.001.002-.006a1.893 1.893 0 00-3.266-1.842 1.892 1.892 0 00-3.082 1.939 1.893 1.893 0 00.244 3.742h.007l-.004.005a1.893 1.893 0 002.817 2.445v2.596a.438.438 0 00.875 0v-2.602a1.893 1.893 0 002.766-2.501l-.004-.006h.006a1.894 1.894 0 001.708-2.063zm-4.952.472l-.003.014-.003-.014-.014.004.011-.01-.005-.005h.002l.003-.02.008.02.014-.006-.01.01.012.01zM327.67 17.991l-2.741 1.777a.438.438 0 00.476.735l2.741-1.777a.438.438 0 00-.476-.735zM323.277 19.768l-2.741-1.777a.438.438 0 00-.476.735l2.74 1.777a.438.438 0 10.477-.735z" />
      </g>
    </svg>
  );
}

export default SvgBand;
