import * as React from "react";

function SvgGradientQuestion(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 78 78"
      {...props}
    >
      <defs>
        <linearGradient
          id="gradient-question_svg__a"
          x1={29.12}
          y1={22.429}
          x2={47.668}
          y2={40.977}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#c0e4f9" />
          <stop offset={0.486} stopColor="#009fe8" />
          <stop offset={0.834} stopColor="#0080c0" />
        </linearGradient>
        <linearGradient
          id="gradient-question_svg__b"
          x1={35.316}
          y1={52.872}
          x2={41.058}
          y2={58.615}
          xlinkHref="#gradient-question_svg__a"
        />
        <linearGradient
          id="gradient-question_svg__c"
          x1={16.229}
          y1={16.229}
          x2={61.772}
          y2={61.772}
          xlinkHref="#gradient-question_svg__a"
        />
      </defs>
      <g opacity={0.5}>
        <path
          d="M45.73 20.564a15.638 15.638 0 00-6.865-1.435 15.016 15.016 0 00-7.32 1.717 12.594 12.594 0 00-4.774 4.33 9.697 9.697 0 00-1.634 5.162 3.238 3.238 0 001.032 2.293 3.4 3.4 0 002.534 1.059q2.546 0 3.459-3.03a11.85 11.85 0 012.359-4.384 5.695 5.695 0 014.344-1.488 5.827 5.827 0 014.116 1.475 4.739 4.739 0 011.595 3.62 4.124 4.124 0 01-.522 2.037 7.728 7.728 0 01-1.287 1.703q-.765.765-2.48 2.266a42.402 42.402 0 00-3.111 2.963 9.512 9.512 0 00-2.547 6.797 3.58 3.58 0 00.951 2.709 3.262 3.262 0 002.347.911 2.983 2.983 0 003.19-2.788q.296-1.314.443-1.837a5.549 5.549 0 01.416-1.046 5.897 5.897 0 01.818-1.153 18.365 18.365 0 011.462-1.461q3.297-2.95 4.57-4.197a12.215 12.215 0 002.2-2.963 8.285 8.285 0 00.925-3.995 9.565 9.565 0 00-1.623-5.363 11.133 11.133 0 00-4.598-3.902z"
          fill="url(#gradient-question_svg__a)"
        />
        <path
          d="M38.221 51.709a3.985 3.985 0 00-2.895 1.153 3.815 3.815 0 00-1.18 2.843 3.713 3.713 0 001.22 2.963 4.218 4.218 0 002.855 1.059 4.074 4.074 0 002.789-1.073 3.744 3.744 0 001.206-2.95 3.937 3.937 0 00-3.995-3.995z"
          fill="url(#gradient-question_svg__b)"
        />
        <path
          d="M39 6.781A32.219 32.219 0 1071.219 39 32.255 32.255 0 0039 6.781zm0 59.438A27.219 27.219 0 1166.219 39 27.25 27.25 0 0139 66.219z"
          fill="url(#gradient-question_svg__c)"
        />
      </g>
    </svg>
  );
}

export default SvgGradientQuestion;
