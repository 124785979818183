import * as React from "react";

function SvgGradientMessagePop(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 78 78"
      {...props}
    >
      <defs>
        <linearGradient
          id="gradient-message-pop_svg__a"
          x1={8.307}
          y1={7.354}
          x2={55.307}
          y2={54.353}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#c0e4f9" />
          <stop offset={0.486} stopColor="#009fe8" />
          <stop offset={0.834} stopColor="#0080c0" />
        </linearGradient>
        <linearGradient
          id="gradient-message-pop_svg__b"
          x1={46.25}
          y1={37.41}
          x2={71.09}
          y2={62.25}
          xlinkHref="#gradient-message-pop_svg__a"
        />
      </defs>
      <g opacity={0.5} fillRule="evenodd">
        <path
          fill="url(#gradient-message-pop_svg__a)"
          d="M60.5 12.16h-57v37h10v10.774l9.228-10.774H60.5v-37z"
        />
        <path
          fill="url(#gradient-message-pop_svg__b)"
          d="M62.5 31.16v20h-30v7h27.285l7.715 7.68v-7.68h7v-27h-12z"
        />
      </g>
    </svg>
  );
}

export default SvgGradientMessagePop;
