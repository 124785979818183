import * as React from "react";

function SvgHomeRecycle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 78 78"
      {...props}
    >
      <g fill="#c89740">
        <path d="M32.489 18.515a5.802 5.802 0 00-.857-1.283 3.265 3.265 0 01-.555-1.754c-.003-.026-.007-.05-.043-.043-.417.945-1.452 2.002-1.326 3.508a1.49 1.49 0 001.626 1.541c.017.081.015.184.127.171.041-.08-.127-.144 0-.17a1.458 1.458 0 001.028-1.97zM29.408 20.912c-1.725-1.748-5.174-2.38-7.957-2.738-.028.002-.052.006-.043.042a7.36 7.36 0 012.652 4.707 5.496 5.496 0 00.984 2.609 3.754 3.754 0 002.268 1.327 3.053 3.053 0 002.31-.727 12.322 12.322 0 01.514 1.626c.156-.001.192-.122.3-.172a7.955 7.955 0 01-.685-1.583 3.348 3.348 0 00.983-2.268 4.228 4.228 0 00-1.326-2.823zM35.912 24.72a8.265 8.265 0 00.213-2.097 4.408 4.408 0 011.156-2.481c-.006-.053-.014-.102-.086-.086-1.975.669-5.047 1.527-5.263 3.893a2.044 2.044 0 00.856 1.883c-.037.33-.363.974-.171 1.113.238-.007.104-.336.129-.514a2.425 2.425 0 01.127-.556c1.207.817 2.634-.162 3.039-1.155z" />
        <path d="M41.216 55.766c.03-4-.056-8 .043-12h5.261v12h7v-17h5.281c-6.122-3-13.052-7.44-19.302-11.386-2.191 1.345-4.979 3.314-6.979 4.57v-4.184h-4l.022 6.736a68.305 68.305 0 01-7.134 4.264h3.112v17zM29.52 44.4a4.348 4.348 0 01.786-.634h6.214v7h-7zM26.46 13.636a27.188 27.188 0 0136.414 11.706l-2.902 1.205 9.087 7.958.776-12.053-2.552 1.06-.007-.014A31.988 31.988 0 0024.28 9.431c-.182.092-.36.187-.54.283l2.143 4.224q.286-.154.577-.303z" />
        <path d="M66.55 38.856c-.012.217-.024.435-.041.653a27.186 27.186 0 01-28.885 25.073l.475-3.108-11.515 3.647 9.904 6.912.417-2.733.015.002a31.99 31.99 0 0034.316-29.479c.015-.203.026-.406.039-.608zM16.301 23.748l2.42 2.006 2.804-11.748-11.027 4.93 2.13 1.765-.01.013a31.99 31.99 0 007.601 44.595c.166.118.334.232.501.346l2.74-3.865c-.18-.121-.36-.244-.538-.371a27.185 27.185 0 01-6.62-37.671z" />
      </g>
    </svg>
  );
}

export default SvgHomeRecycle;
