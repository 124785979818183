import * as React from "react";

function SvgMessagePop(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 78 78"
      {...props}
    >
      <g fill="#dc007a" fillRule="evenodd">
        <path d="M61.115 16.177a2.843 2.843 0 00-2.835-2.834H6.95a2.843 2.843 0 00-2.835 2.834v31.331a2.843 2.843 0 002.835 2.835h4.33a2.843 2.843 0 012.835 2.834v5.104c0 1.56.83 1.866 1.844.682l5.54-6.467a7.028 7.028 0 014.679-2.153h32.103a2.843 2.843 0 002.834-2.835z" />
        <path d="M63.637 31.346h-1.469v16.882a4.486 4.486 0 01-3.118 3.26H31.885v4.024a2.843 2.843 0 002.835 2.834h21.615a7.915 7.915 0 014.843 2l3.698 3.68c1.105 1.1 2.009.725 2.009-.834V61.18a2.843 2.843 0 012.835-2.835h1.33a2.843 2.843 0 002.835-2.834V34.18a2.843 2.843 0 00-2.835-2.835z" />
      </g>
    </svg>
  );
}

export default SvgMessagePop;
