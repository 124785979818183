import * as React from "react";

function SvgGradientBuilding(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 78 78"
      {...props}
    >
      <defs>
        <linearGradient
          id="gradient-building_svg__a"
          x1={15.039}
          y1={24.319}
          x2={65.963}
          y2={75.244}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#c0e4f9" />
          <stop offset={0.486} stopColor="#009fe8" />
          <stop offset={0.834} stopColor="#0080c0" />
        </linearGradient>
        <linearGradient
          id="gradient-building_svg__b"
          x1={36.762}
          y1={24.329}
          x2={39.951}
          y2={27.519}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__c"
          x1={43.14}
          y1={24.329}
          x2={46.329}
          y2={27.518}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__d"
          x1={49.518}
          y1={24.33}
          x2={52.706}
          y2={27.518}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__e"
          x1={36.762}
          y1={17.951}
          x2={39.951}
          y2={21.14}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__f"
          x1={43.14}
          y1={17.951}
          x2={46.329}
          y2={21.14}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__g"
          x1={49.518}
          y1={17.952}
          x2={52.706}
          y2={21.14}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__h"
          x1={36.761}
          y1={37.084}
          x2={39.952}
          y2={40.275}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__i"
          x1={43.139}
          y1={37.084}
          x2={46.33}
          y2={40.275}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__j"
          x1={49.517}
          y1={37.085}
          x2={52.706}
          y2={40.274}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__k"
          x1={36.762}
          y1={43.462}
          x2={39.951}
          y2={46.651}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__l"
          x1={49.518}
          y1={43.462}
          x2={52.706}
          y2={46.65}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__m"
          x1={36.761}
          y1={30.706}
          x2={39.952}
          y2={33.896}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__n"
          x1={43.139}
          y1={30.706}
          x2={46.33}
          y2={33.896}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__o"
          x1={49.517}
          y1={30.706}
          x2={52.706}
          y2={33.896}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__p"
          x1={43.14}
          y1={43.462}
          x2={46.329}
          y2={46.651}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__q"
          x1={20.319}
          y1={37.085}
          x2={23.508}
          y2={40.274}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__r"
          x1={26.697}
          y1={37.085}
          x2={29.886}
          y2={40.274}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__s"
          x1={20.319}
          y1={30.706}
          x2={23.508}
          y2={33.896}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__t"
          x1={26.697}
          y1={30.706}
          x2={29.886}
          y2={33.896}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__u"
          x1={20.318}
          y1={49.84}
          x2={23.509}
          y2={53.03}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__v"
          x1={26.697}
          y1={49.84}
          x2={29.887}
          y2={53.03}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__w"
          x1={20.319}
          y1={56.217}
          x2={23.508}
          y2={59.406}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__x"
          x1={20.318}
          y1={43.461}
          x2={23.509}
          y2={46.652}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__y"
          x1={26.697}
          y1={43.461}
          x2={29.887}
          y2={46.652}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__z"
          x1={26.697}
          y1={56.217}
          x2={29.886}
          y2={59.406}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__A"
          x1={61.675}
          y1={37.085}
          x2={64.864}
          y2={40.274}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__B"
          x1={68.053}
          y1={37.085}
          x2={71.243}
          y2={40.274}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__C"
          x1={61.674}
          y1={49.84}
          x2={64.865}
          y2={53.03}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__D"
          x1={68.053}
          y1={49.84}
          x2={71.243}
          y2={53.03}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__E"
          x1={61.675}
          y1={56.217}
          x2={64.864}
          y2={59.406}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__F"
          x1={61.674}
          y1={43.461}
          x2={64.865}
          y2={46.652}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__G"
          x1={68.053}
          y1={43.461}
          x2={71.243}
          y2={46.652}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__H"
          x1={68.053}
          y1={56.217}
          x2={71.243}
          y2={59.406}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__I"
          x1={5.121}
          y1={49.84}
          x2={8.312}
          y2={53.03}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__J"
          x1={11.5}
          y1={49.84}
          x2={14.69}
          y2={53.03}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__K"
          x1={5.122}
          y1={56.217}
          x2={8.311}
          y2={59.406}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__L"
          x1={5.121}
          y1={43.461}
          x2={8.312}
          y2={46.652}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__M"
          x1={11.5}
          y1={43.461}
          x2={14.69}
          y2={46.652}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__N"
          x1={11.5}
          y1={56.217}
          x2={14.689}
          y2={59.406}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__O"
          x1={36.762}
          y1={56.218}
          x2={39.951}
          y2={59.408}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__P"
          x1={49.518}
          y1={56.219}
          x2={52.706}
          y2={59.407}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__Q"
          x1={36.762}
          y1={49.84}
          x2={39.951}
          y2={53.029}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__R"
          x1={43.14}
          y1={49.84}
          x2={46.329}
          y2={53.029}
          xlinkHref="#gradient-building_svg__a"
        />
        <linearGradient
          id="gradient-building_svg__S"
          x1={49.518}
          y1={49.841}
          x2={52.706}
          y2={53.029}
          xlinkHref="#gradient-building_svg__a"
        />
      </defs>
      <g opacity={0.5}>
        <path
          d="M74.472 31.028h-6.417v-3.012h-3.19v3.012h-6.17V15.374a2.91 2.91 0 00-2.908-2.907h-21.84a2.91 2.91 0 00-2.907 2.907v7.557H26.7v-3.385h-3.19v3.385H19a2.91 2.91 0 00-2.907 2.907v11.194H3.528A2.91 2.91 0 00.621 39.94v22.686a2.91 2.91 0 002.907 2.907h70.944a2.91 2.91 0 002.907-2.907V33.934a2.91 2.91 0 00-2.907-2.907zm-58.38 31.515H3.611V40.022h12.481zm14.948 0H19.082V25.92H31.04zm24.665-31.515v31.515H46.33v-6.324h-3.19v6.324h-9.11V15.457h21.675zM74.39 62.543H58.694V34.017H74.39z"
          fillRule="evenodd"
          fill="url(#gradient-building_svg__a)"
        />
        <path
          fill="url(#gradient-building_svg__b)"
          d="M36.761 24.33h3.191v3.188h-3.191z"
        />
        <path
          fill="url(#gradient-building_svg__c)"
          d="M43.139 24.33h3.19v3.188h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__d)"
          d="M49.518 24.33h3.188v3.188h-3.188z"
        />
        <path
          fill="url(#gradient-building_svg__e)"
          d="M36.761 17.952h3.191v3.188h-3.191z"
        />
        <path
          fill="url(#gradient-building_svg__f)"
          d="M43.139 17.952h3.19v3.188h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__g)"
          d="M49.518 17.952h3.188v3.188h-3.188z"
        />
        <path
          fill="url(#gradient-building_svg__h)"
          d="M36.761 37.084h3.191v3.19h-3.191z"
        />
        <path
          fill="url(#gradient-building_svg__i)"
          d="M43.139 37.084h3.19v3.19h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__j)"
          d="M49.518 37.084h3.188v3.19h-3.188z"
        />
        <path
          fill="url(#gradient-building_svg__k)"
          d="M36.761 43.462h3.191v3.188h-3.191z"
        />
        <path
          fill="url(#gradient-building_svg__l)"
          d="M49.518 43.462h3.188v3.188h-3.188z"
        />
        <path
          fill="url(#gradient-building_svg__m)"
          d="M36.761 30.706h3.191v3.19h-3.191z"
        />
        <path
          fill="url(#gradient-building_svg__n)"
          d="M43.139 30.706h3.19v3.19h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__o)"
          d="M49.518 30.706h3.188v3.19h-3.188z"
        />
        <path
          fill="url(#gradient-building_svg__p)"
          d="M43.139 43.462h3.19v3.188h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__q)"
          d="M20.318 37.086h3.19v3.188h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__r)"
          d="M26.697 37.086h3.19v3.188h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__s)"
          d="M20.318 30.707h3.19v3.188h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__t)"
          d="M26.697 30.707h3.19v3.188h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__u)"
          d="M20.318 49.84h3.19v3.19h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__v)"
          d="M26.697 49.84h3.19v3.19h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__w)"
          d="M20.318 56.218h3.19v3.188h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__x)"
          d="M20.318 43.461h3.19v3.19h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__y)"
          d="M26.697 43.461h3.19v3.19h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__z)"
          d="M26.697 56.218h3.19v3.188h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__A)"
          d="M61.674 37.086h3.19v3.188h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__B)"
          d="M68.053 37.086h3.19v3.188h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__C)"
          d="M61.674 49.84h3.19v3.19h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__D)"
          d="M68.053 49.84h3.19v3.19h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__E)"
          d="M61.674 56.218h3.19v3.188h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__F)"
          d="M61.674 43.461h3.19v3.19h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__G)"
          d="M68.053 43.461h3.19v3.19h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__H)"
          d="M68.053 56.218h3.19v3.188h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__I)"
          d="M5.121 49.84h3.19v3.19h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__J)"
          d="M11.5 49.84h3.19v3.19H11.5z"
        />
        <path
          fill="url(#gradient-building_svg__K)"
          d="M5.121 56.218h3.19v3.188h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__L)"
          d="M5.121 43.461h3.19v3.19h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__M)"
          d="M11.5 43.461h3.19v3.19H11.5z"
        />
        <path
          fill="url(#gradient-building_svg__N)"
          d="M11.5 56.218h3.19v3.188H11.5z"
        />
        <path
          fill="url(#gradient-building_svg__O)"
          d="M36.761 56.219h3.191v3.188h-3.191z"
        />
        <path
          fill="url(#gradient-building_svg__P)"
          d="M49.518 56.219h3.188v3.188h-3.188z"
        />
        <path
          fill="url(#gradient-building_svg__Q)"
          d="M36.761 49.841h3.191v3.188h-3.191z"
        />
        <path
          fill="url(#gradient-building_svg__R)"
          d="M43.139 49.841h3.19v3.188h-3.19z"
        />
        <path
          fill="url(#gradient-building_svg__S)"
          d="M49.518 49.841h3.188v3.188h-3.188z"
        />
      </g>
    </svg>
  );
}

export default SvgGradientBuilding;
