import React from 'react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import SvgBand from '../generated/svg/band';
import SvgSdgs from '../generated/svg/sdgs';
import SvgUsa from '../generated/svg/usa';

const Outer = styled.div`
  background-color: #efefef;
  padding: 2rem 2rem 8rem;
  display: flex;
  justify-content: center;
  position: relative;
  gap: 4rem;
  @media (max-width: 50rem) {
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 6rem;
  }
`

const Label = styled.div`
  padding: 1rem 0;
  font-size: 1.5rem;
  white-space: nowrap;
`

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const Item = styled.div`
  display: flex;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 6rem;
  }
`

const TextContainer = styled.div`
  margin-left: 3rem;
  padding: 1rem 0;
`

const Title = styled.div`
  font-size: 1.5rem;
  line-height: 1.5;
  margin-top: -0.25rem;
`

const Text = styled.div`
  margin-top: 1rem;
  line-height: 1.75;
`

const BandContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  svg {
    width: 100%;
    height: calc(24.614 / 655.353 * 100vw);
    display: block;
    @media (max-width: 50rem) {
      width: 60rem;
      min-width: 60rem;
      height: calc(24.614 / 655.353 * 60rem);
    }
  }
`


export const About:FunctionComponent = () => {
  return <Outer>
    <Label>
      ABOUT US
    </Label>
    <ItemContainer>
      <Item>
        <IconContainer>
          <SvgUsa />
        </IconContainer>
        <TextContainer>
          <Title>
            お困りごとはございませんか？
          </Title>
          <Text>
            「お引越しやリフォームでゴミが沢山・・・」<br />
            「お部屋に溜まった不用品を処分したい・・・」<br />
            そんな時はお気軽にご連絡ください！<br />
            不用品回収の出張見積り・買取り査定は無料です
          </Text>
        </TextContainer>
      </Item>
      <Item>
        <IconContainer>
          <SvgSdgs />
        </IconContainer>
        <TextContainer>
          <Title>
            持続可能な社会のために
          </Title>
          <Text>
            エコステップはSDGsに賛同します。<br />
            限りある資源の有効活用と環境保全のために、不用品の廃棄処分だけで<br />
            はなくまだ使える物は自社店舗にてリサイクル販売しております。
          </Text>
        </TextContainer>
      </Item>
    </ItemContainer>
    <BandContainer>
      <SvgBand />
    </BandContainer>
  </Outer>
}
