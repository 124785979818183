import * as React from "react";

function SvgLineIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35.143 33.485"
      {...props}
    >
      <path
        d="M35.143 14.26C35.143 6.396 27.26 0 17.57 0S0 6.397 0 14.26c0 7.048 6.251 12.951 14.695 14.068.572.123 1.351.377 1.548.866a3.608 3.608 0 01.057 1.59l-.25 1.503c-.077.444-.354 1.738 1.521.947s10.12-5.958 13.806-10.201a12.687 12.687 0 003.766-8.774zm-23.771 4.197a.342.342 0 01-.342.343H6.106a.34.34 0 01-.236-.095l-.005-.005-.005-.006a.34.34 0 01-.096-.236v-7.656a.342.342 0 01.343-.342h1.232a.342.342 0 01.342.342v6.08h3.349a.342.342 0 01.342.343zm2.972.001a.342.342 0 01-.343.341H12.77a.342.342 0 01-.343-.341v-7.656a.343.343 0 01.343-.342H14a.342.342 0 01.342.342zm8.483 0a.342.342 0 01-.342.341h-1.233a.33.33 0 01-.087-.011l-.005-.001-.024-.008-.01-.004-.016-.007-.017-.008-.01-.005-.02-.013-.004-.003a.333.333 0 01-.087-.084l-3.513-4.744v4.547a.342.342 0 01-.343.341h-1.232a.342.342 0 01-.342-.341v-7.656a.342.342 0 01.342-.342h1.244l.018.002.018.002.014.002.02.004.012.003.02.007.012.004.02.008.01.005.019.01.01.007.018.012.009.007.018.015.006.006.02.02.002.003a.357.357 0 01.027.035l3.509 4.738v-4.548a.342.342 0 01.342-.342h1.233a.342.342 0 01.342.342zm6.806-6.423a.342.342 0 01-.342.342h-3.349v1.294h3.349a.342.342 0 01.342.343v1.232a.342.342 0 01-.342.342h-3.349v1.294h3.349a.342.342 0 01.342.343v1.232a.342.342 0 01-.342.343h-4.924a.34.34 0 01-.236-.096l-.005-.004-.005-.006a.34.34 0 01-.096-.236v-7.656a.34.34 0 01.095-.236l.005-.006.004-.004a.34.34 0 01.238-.096h4.924a.342.342 0 01.342.342z"
        fill="currentcolor"
      />
    </svg>
  );
}

export default SvgLineIcon;
