import React from 'react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { About } from '../components/about';
import { Banners } from '../components/banner';
import { News } from '../components/news';
import { TopPageContent } from '../components/top-page-content';
import { TopPageSlider } from '../components/top-page-slider';
import { useBreakpoint } from '../hooks/use-breakpoint';

const Outer = styled.div`
  background-color: #93afc2;
  @media (max-width: 50rem) {
    background-color: #002132;
  }
  padding-bottom: var(--gap);
`
const TopPageSliderOuter = styled.div``

const AboutOuter = styled.div`
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.2);
`


const BannerContainer = styled.div`
  margin: 0 auto;
  padding: 3rem var(--gap) 0 var(--gap);
  max-width: 60rem;
`

const NewsOuter = styled.div`
  margin: 0 auto;
  padding: var(--gap) var(--gap) 0 var(--gap);
  max-width: 60rem;
`

const ContentOuter = styled.div`
  margin: 0 auto;
  padding: var(--gap) var(--gap) 0 var(--gap);
  max-width: 60rem;
`


export const TopPage:FunctionComponent = () => {
  return <Outer>
    <TopPageSliderOuter>
      <TopPageSlider />
    </TopPageSliderOuter>
    <AboutOuter>
      <About />
    </AboutOuter>
    <BannerContainer>
      <Banners />
    </BannerContainer>
    <NewsOuter>
      <News />
    </NewsOuter>
    <ContentOuter>
      <TopPageContent />
    </ContentOuter>
  </Outer>
}
