import React from 'react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import SvgBuildings from '../generated/svg/buildings';
import SvgGradientBuilding from '../generated/svg/gradient-building';
import SvgGradientHomeRecycle from '../generated/svg/gradient-home-recycle';
import SvgGradientMessagePop from '../generated/svg/gradient-message-pop';
import SvgGradientQuestion from '../generated/svg/gradient-question';
import SvgGradientRecycle from '../generated/svg/gradient-recycle';
import SvgGradientSield from '../generated/svg/gradient-sield';
import SvgHomeRecycle from '../generated/svg/home-recycle';
import SvgMessagePop from '../generated/svg/message-pop';
import SvgQuestion from '../generated/svg/question';
import SvgRecycle from '../generated/svg/recycle';
import SvgSield from '../generated/svg/sield';
import { useBreakpoint } from '../hooks/use-breakpoint';

const Outer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gap);
  @media(max-width: 50rem) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Item = styled.a`

  border-radius: 1rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;

  @media(min-width: 50rem) {
    &:hover {
      transform: scale(1.1);
    }
  }
  
  @media(max-width: 50rem) {
    color: white;
    background-color: #003049;
  }
`

const Label = styled.div`
  font-size: 1.3rem;
  @media(max-width: 50rem) {
    font-size: 1rem;
  }
`

const IconContainer = styled.div`
  height: 6rem;
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  svg {
    width: 7.5rem;
    height: auto;
  }
`

const Text = styled.div`
  margin-top: 2.5rem;
  line-height: 1.5;
  text-align: center;
  @media(max-width: 50rem) {
    font-size: 0.75rem;
  }
`

export const Banners: FunctionComponent = () => {
  const isMobile = useBreakpoint('50rem')
  return <Outer>
    <Item href="/recycle/">
      <Label>
        リサイクルショップ
      </Label>
      <IconContainer>
        {
          isMobile ?
          <SvgGradientRecycle />:
          <SvgRecycle />
        }
      </IconContainer>
      <Text>
        実店舗があるから買取に強い！
      </Text>
    </Item>
    <Item href="/jirei/">
      <Label>
        回収事例集
      </Label>
      <IconContainer>
        {
          isMobile ?
          <SvgGradientMessagePop />:
          <SvgMessagePop />
        }
      </IconContainer>
      <Text>
        料金の目安や買取品の例はこちら
      </Text>
    </Item>
    <Item href="/office/">
      <Label>
      オフィス回収
      </Label>
      <IconContainer>
        {
          isMobile ?
          <SvgGradientBuilding />:
          <SvgBuildings />
        }
      </IconContainer>
      <Text>
        事務所の片付けをお手伝いします！
      </Text>
    </Item>
    <Item href="/ihinseiri/">
      <Label>
      遺品整理
      </Label>
      <IconContainer>
        {
          isMobile ?
          <SvgGradientHomeRecycle />:
          <SvgHomeRecycle />
        }
      </IconContainer>
      <Text>
        一軒丸ごとお任せください！
      </Text>
    </Item>
    <Item href="/qa/">
      <Label>
      Q &amp; A
      </Label>
      <IconContainer>
        {
          isMobile ?
          <SvgGradientQuestion />:
          <SvgQuestion />
        }
      </IconContainer>
      <Text>
        よくあるご質問をまとめました
      </Text>
    </Item>
    <Item href="/compensation/">
      <Label>
      賠償保障制度
      </Label>
      <IconContainer>
        {
          isMobile ?
          <SvgGradientSield />:
          <SvgSield />
        }
      </IconContainer>
      <Text>
        万が一の事故には保険でカバー
      </Text>
    </Item>
  </Outer>
}