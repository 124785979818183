import * as React from "react";

function SvgRightArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 6.803 9.717"
      {...props}
    >
      <path fill="currentcolor" d="M0 0l6.803 4.858L0 9.717" />
    </svg>
  );
}

export default SvgRightArrow;
