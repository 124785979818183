import * as React from "react";

function SvgRecycle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 78 78"
      {...props}
    >
      <path
        d="M64.794 23.966H55.89a16.866 16.866 0 00-33.71 0h-8.975a2.42 2.42 0 00-2.391 2.785l6.34 41.504a2.42 2.42 0 002.392 2.054h38.906a2.42 2.42 0 002.392-2.054l6.34-41.504a2.42 2.42 0 00-2.391-2.785zm-25.758-11.71a12.316 12.316 0 0112.286 11.71H26.75a12.316 12.316 0 0112.286-11.71zm-1.143 23.378h.009l.669 1.151-4.744 8.196-5.527-3.195 4.722-8.236 1.54.011a3.192 3.192 0 013.331 2.073zm-9.917 20.628l-.613-1.073-2.332-4.038h-.002c-1.128-1.953-.619-2.617.265-4.145.062-.107.148-.189.212-.304l.568-1.022-1.798-1.052 6.11-.02 3.038 5.26-1.78-1.03-2.52 4.327a7.457 7.457 0 00-1.019 2.274zm11.384 3.583l-9.494.029-.76-1.34a3.192 3.192 0 01.13-3.921l-.005-.007.663-1.156 9.47.011zm-.157-23.517h.001a8.812 8.812 0 00-1.455-2.052l-.643-.557 1.246.006h4.686v-.002c2.283 0 2.554.776 3.436 2.305.062.108.068.227.135.34l.602 1.01 1.809-1.016-3.037 5.331h-6.075l1.781-1.022zM51.44 53.956l-2.331 4.04h.001c-1.128 1.954-1.957 1.845-3.722 1.844-.124 0-.238-.033-.37-.032l-1.17.02-.011 2.082-3.073-5.281 3.037-5.26-.002 2.055 5.007.02a7.46 7.46 0 002.48-.254l.777-.3zm-1.435-1.332l-.004.007-1.332.004-4.725-8.206 5.53-3.19 4.772 8.208-.78 1.329a3.192 3.192 0 01-3.461 1.848zm4.24-.456z"
        fill="#25a739"
      />
    </svg>
  );
}

export default SvgRecycle;
