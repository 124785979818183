import React from 'react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { LineIcon } from '../generated/svg';
import SvgFreeTelIcon from '../generated/svg/free-tel-icon';
import SvgLineIcon from '../generated/svg/line-icon';

const Outer = styled.div`
  background-color: #002132;
  padding: 0 var(--gap);
  padding-bottom: 8rem;
`

const Copyright = styled.div`
  color: white;
  font-size: 0.8rem;
  text-align: center;
  padding: 0.75rem;
`

const ItemContainer = styled.div`
  display: flex;
  gap: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.75rem;
`

const Item = styled.a<{backgroundColor: string}>`
  color: white;
  background-color: ${({backgroundColor}) => backgroundColor};
  border-radius: 1rem 1rem 0 0;
  padding: 0.5rem;
  padding-bottom: calc(env(safe-area-inset-bottom) + 0.5rem);
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  svg {
    width: 2rem;
    height: 2rem;
  }
`

const Label = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 0.75rem;
`

export const MobileFooter: FunctionComponent = () => {
  return <Outer>
    <Copyright>Copyright ©{(new Date()).getFullYear()} ecostep</Copyright>
    <ItemContainer>
      <Item backgroundColor="#3aae36" href="https://line.me/R/ti/p/%40328uuzlx">
        <IconContainer>
          <SvgLineIcon />
        </IconContainer>
        <Label>LINEアプリでお問い合わせ</Label>
      </Item>
      <Item backgroundColor="#e4007f" href="tel:0120916770">
        <IconContainer>
          <SvgFreeTelIcon />
        </IconContainer>
        <Label>電話でお問い合わせ</Label>
      </Item>
    </ItemContainer>
  </Outer>
}