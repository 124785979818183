import React from 'react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTopPageQuery } from '../generated/graphql';

const Outer = styled.div`
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  background-color: #fff;
  padding: 2rem;
  @media(max-width: 50rem) {
    color: white;
    background-color: #003049;
  }
`

export const TopPageContent: FunctionComponent = () => {
  const topPageQuery = useTopPageQuery()
  return <Outer dangerouslySetInnerHTML={{__html: topPageQuery.data?.page?.content ?? ''}}></Outer>
}