import * as React from "react";

function SvgBuildings(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 78 78"
      {...props}
    >
      <g fill="#417935">
        <path
          d="M74.472 31.028h-6.417v-3.012h-3.19v3.012h-6.17V15.374a2.91 2.91 0 00-2.908-2.907h-21.84a2.91 2.91 0 00-2.907 2.907v7.557H26.7v-3.385h-3.19v3.385H19a2.91 2.91 0 00-2.907 2.907v11.194H3.528A2.91 2.91 0 00.621 39.94v22.686a2.91 2.91 0 002.907 2.907h70.944a2.91 2.91 0 002.907-2.907V33.934a2.91 2.91 0 00-2.907-2.907zm-58.38 31.515H3.611V40.022h12.481zm14.948 0H19.082V25.92H31.04zm24.665-31.515v31.515H46.33v-6.324h-3.19v6.324h-9.11V15.457h21.675zM74.39 62.543H58.694V34.017H74.39z"
          fillRule="evenodd"
        />
        <path d="M36.761 24.33h3.191v3.188h-3.191zM43.139 24.33h3.19v3.188h-3.19zM49.518 24.33h3.188v3.188h-3.188zM36.761 17.952h3.191v3.188h-3.191zM43.139 17.952h3.19v3.188h-3.19zM49.518 17.952h3.188v3.188h-3.188zM36.761 37.084h3.191v3.19h-3.191zM43.139 37.084h3.19v3.19h-3.19zM49.518 37.084h3.188v3.19h-3.188zM36.761 43.462h3.191v3.188h-3.191zM49.518 43.462h3.188v3.188h-3.188zM36.761 30.706h3.191v3.19h-3.191zM43.139 30.706h3.19v3.19h-3.19zM49.518 30.706h3.188v3.19h-3.188zM43.139 43.462h3.19v3.188h-3.19zM20.318 37.085h3.19v3.188h-3.19zM26.697 37.085h3.19v3.188h-3.19zM20.318 30.707h3.19v3.188h-3.19zM26.697 30.707h3.19v3.188h-3.19zM20.318 49.84h3.19v3.19h-3.19zM26.697 49.84h3.19v3.19h-3.19zM20.318 56.218h3.19v3.188h-3.19zM20.318 43.461h3.19v3.19h-3.19zM26.697 43.461h3.19v3.19h-3.19zM26.697 56.218h3.19v3.188h-3.19zM61.674 37.085h3.19v3.188h-3.19zM68.053 37.085h3.19v3.188h-3.19zM61.674 49.84h3.19v3.19h-3.19zM68.053 49.84h3.19v3.19h-3.19zM61.674 56.218h3.19v3.188h-3.19zM61.674 43.461h3.19v3.19h-3.19zM68.053 43.461h3.19v3.19h-3.19zM68.053 56.218h3.19v3.188h-3.19zM5.121 49.84h3.19v3.19h-3.19zM11.5 49.84h3.19v3.19H11.5zM5.121 56.218h3.19v3.188h-3.19zM5.121 43.461h3.19v3.19h-3.19zM11.5 43.461h3.19v3.19H11.5zM11.5 56.218h3.19v3.188H11.5zM36.761 56.219h3.191v3.188h-3.191zM49.518 56.219h3.188v3.188h-3.188zM36.761 49.841h3.191v3.188h-3.191zM43.139 49.841h3.19v3.188h-3.19zM49.518 49.841h3.188v3.188h-3.188z" />
      </g>
    </svg>
  );
}

export default SvgBuildings;
