import * as React from "react";

function SvgQuestion(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 78 78"
      {...props}
    >
      <g fill="#e73819">
        <path d="M45.73 20.564a15.638 15.638 0 00-6.865-1.435 15.016 15.016 0 00-7.32 1.717 12.594 12.594 0 00-4.774 4.33 9.697 9.697 0 00-1.634 5.162 3.238 3.238 0 001.032 2.293 3.4 3.4 0 002.534 1.059q2.545 0 3.459-3.03a11.851 11.851 0 012.36-4.384 5.695 5.695 0 014.343-1.488 5.827 5.827 0 014.116 1.474 4.739 4.739 0 011.595 3.62 4.124 4.124 0 01-.521 2.038 7.728 7.728 0 01-1.287 1.703q-.765.764-2.481 2.266a42.376 42.376 0 00-3.111 2.963 9.511 9.511 0 00-2.547 6.797 3.58 3.58 0 00.951 2.709 3.263 3.263 0 002.347.911 2.983 2.983 0 003.19-2.788q.296-1.314.443-1.837a5.54 5.54 0 01.416-1.046 5.89 5.89 0 01.818-1.153 18.365 18.365 0 011.462-1.461q3.297-2.95 4.57-4.197a12.212 12.212 0 002.199-2.963 8.285 8.285 0 00.926-3.995 9.565 9.565 0 00-1.623-5.363 11.132 11.132 0 00-4.598-3.902zM38.221 51.709a3.985 3.985 0 00-2.895 1.153 3.814 3.814 0 00-1.18 2.843 3.713 3.713 0 001.22 2.963 4.218 4.218 0 002.855 1.059 4.074 4.074 0 002.789-1.073 3.744 3.744 0 001.206-2.95 3.938 3.938 0 00-3.995-3.995z" />
        <path d="M39 6.781A32.219 32.219 0 1071.219 39 32.255 32.255 0 0039 6.781zm0 59.438A27.219 27.219 0 1166.219 39 27.25 27.25 0 0139 66.219z" />
      </g>
    </svg>
  );
}

export default SvgQuestion;
