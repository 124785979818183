import React, { useState } from 'react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import SvgFreeTelIcon from '../generated/svg/free-tel-icon';
import SvgHumburger from '../generated/svg/humburger';
import SvgLogo from '../generated/svg/logo';
import { Menu } from './menu';

const Outer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2rem;
  background-color: rgba(255, 255, 255, 0.8);
`

const LogoContainer = styled.a`
  margin-right: auto;
  svg {
    height: 3.5rem;
    width: auto;
    @media (max-width: 50rem) {
      height: 2.5rem;
    }
  }
`

const TelOuter = styled.a`
  display: block;
  @media (max-width: 50rem) {
    display: none;
  }
`

const TelLabel = styled.div`
  margin-bottom: 0.25rem;
  font-size: 0.8rem;
`

const NumberContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const TelIconContainer = styled.div`
  margin-right: 1rem;
  margin-top: 0.5rem;
  svg {
    width: auto;
    height: 3rem;
  }
`

const TelNumber = styled.div`
  font-family: 'Bebas Neue';
  font-weight: 400;
  font-size: 3rem;
  letter-spacing: 0.05em;
`

const MenuButton = styled.button`
  display: block;
  border: 0 none;
  padding: 2rem;
  margin-left: 2rem;
  background-color: #fff;
  cursor: pointer;
  @media (max-width: 50rem) {
    padding: 1rem;
    background-color: transparent;
  }
`

const MenuButtonIconContainer = styled.div`
  transition: opacity 0.4s;
  ${MenuButton}:hover & {
    opacity: 0.5;
  }
  svg {
    height: 2rem;
    width: 2rem;
  }
`

const MenuOuter = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
`

export const Header:FunctionComponent = () => {
  const [show, setShow] = useState(false)
  console.log(show)
  return <Outer>
    <LogoContainer href="/">
      <SvgLogo />
    </LogoContainer>
    <TelOuter href="tel:0120916770">
      <TelLabel>
        {document.getElementById('title-h1')?.textContent || '埼玉県の不用品回収が安い・買取りできる業者はエコステップ！'}
      </TelLabel>
      <NumberContainer>
        <TelIconContainer>
          <SvgFreeTelIcon />
        </TelIconContainer>
        <TelNumber>0120-916-770</TelNumber>
      </NumberContainer>
    </TelOuter>
    <MenuButton onClick={() => setShow(v => !v)}>
      <MenuButtonIconContainer>
        <SvgHumburger />
      </MenuButtonIconContainer>
    </MenuButton>
    <MenuOuter>
      <Menu show={show} onClose={() => setShow(false)}/>
    </MenuOuter>
  </Outer>
}
