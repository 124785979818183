import React from 'react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import SvgLineIcon from '../generated/svg/line-icon';

const Outer = styled.a`
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #3aae36;
  gap: 1rem;
  padding: 4rem 1rem;
  border-radius: 0 1rem 1rem 0;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s;
  &:hover {
    background-color: #6ac567;
  }
`

const IconContainer = styled.div`
  svg {
    width: 3rem;
    height: auto;
  }
`

const Label = styled.div`
  font-size: 0.8rem;
  font-weight: 700;
  text-align: center;
  line-height: 1.75;
`


export const SideBanner: FunctionComponent = () => {
  return <Outer href="https://line.me/R/ti/p/%40328uuzlx">
    <IconContainer>
      <SvgLineIcon />
    </IconContainer>
    <Label>
      LINE<br />
      アプリで<br />
      お問い合わせ
    </Label>
  </Outer>
}