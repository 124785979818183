import * as React from "react";

function SvgHumburger(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25.538 19.648"
      {...props}
    >
      <g
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.126}
      >
        <path d="M1.063 1.063h23.412M1.063 9.824h23.412M1.063 18.585h23.412" />
      </g>
    </svg>
  );
}

export default SvgHumburger;
