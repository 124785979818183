import React from 'react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import SvgVerticalLogo from '../generated/svg/vertical-logo';
import { SideBanner } from './side-banner';

const Outer = styled.div`
  background-color: #2a4957;
  color: white;
`

const Inner = styled.div`
  max-width: 60rem;
  margin: 0 auto;
  display: flex;
  padding: 4rem 2rem;
  gap: 4rem;
`

const LeftContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`

const RightContainer = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 40rem;
  margin-left: auto;
`

const LogoContainer = styled.a`
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.5;
  }
  svg {
    width: 8rem;
    height: auto;
  }
`

const Copyright = styled.div`

`

const Item = styled.div`
  display: flex;
`

const Label = styled.div`
  flex: 0 0 6rem;
`

const LinkContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem 1.5rem;
`

const Link = styled.a`
  display: block;
  &:hover {
    text-decoration: underline;
  }
`

const SideBannerOuter = styled.div`
  position: fixed;
  top: 20%;
  left: 0;
  z-index: 10;
`

export const DesktopFooter:FunctionComponent = () => {
  return <Outer>
    <Inner>
      <LeftContainer>
        <LogoContainer href="/">
          <SvgVerticalLogo />
        </LogoContainer>
        <Copyright>Copyright ©{(new Date()).getFullYear()} ecostep</Copyright>
      </LeftContainer>
      <RightContainer>
        <Item>
          <Label>about</Label>
          <LinkContainer>
            <Link href="/company/">会社概要</Link>
            <Link href="/qa/">よくあるご質問</Link>
            <Link href="/compensation/">賠償保障制度</Link>
            <Link href="/blog/">BLOG</Link>
            <Link href="/privacy/">プライバシーポリシー</Link>
            <Link href="/saitama/">対応エリアについて</Link>
            <Link href="/sitemap/">サイトマップ</Link>
            <Link href="/jirei/">回収事例</Link>
          </LinkContainer>
        </Item>
        <Item>
          <Label>service</Label>
          <LinkContainer>
            <Link href="/recycle/">リサイクルショップ</Link>
            <Link href="/purchase/">各種買取</Link>
            <Link href="/ihinseiri/">遺品整理・買取</Link>
            <Link href="/left/">家具回収</Link>
            <Link href="/office/">オフィス回収</Link>
            <Link href="/sodaigomi/">粗大ゴミ回収</Link>
            <Link href="/gomiyasiki/">ゴミ屋敷清掃</Link>
          </LinkContainer>
        </Item>
      </RightContainer>
    </Inner>
    <SideBannerOuter>
      <SideBanner />
    </SideBannerOuter>
  </Outer>
}
