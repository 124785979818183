import React from 'react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { usePostsQuery } from '../generated/graphql';
import { formatDate } from '../utisl/format-date';

const Outer = styled.div`
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  background-color: #fff;
  padding: 2rem;
  display: flex;
  @media(max-width: 50rem) {
    color: white;
    background-color: #003049;
    flex-direction: column;
    gap: 1rem;
  }
`

const Label = styled.div`
  font-size: 1.3rem;
  flex: 0 0 30%;

  @media(max-width: 50rem) {
    font-size: 1rem;
  }
`

const ItemContainer = styled.div`

`

const Item = styled.a`
  display: flex;
  padding: 0.25rem;
  align-items: baseline;
  &:hover {
    text-decoration: underline;
  }

  &:first-child {
    margin-top: -0.25rem;
  }

  &:last-child {
    margin-bottom: -0.25rem;
  }
  @media(max-width: 50rem) {
    padding: 0.25rem 0;
  }
`

const ItemDate = styled.div`

`

const Title = styled.div`
  margin-left: 1rem;
  line-height: 1.5;
`

export const News: FunctionComponent = () => {
  const postsQuery = usePostsQuery()
  return <Outer>
    <Label>最新情報-BLOG</Label>
    <ItemContainer>
      {
        postsQuery.data?.posts?.nodes?.map(post => {
          if(!post) return null
          const dateString = formatDate(new Date(post.date ?? NaN))
          return <Item key={post.id} href={post.uri ?? undefined}>
            <ItemDate>
              {dateString}
            </ItemDate>
            <Title>
              {post.title}
            </Title>
          </Item>
        })
      }
    </ItemContainer>
  </Outer>
}