import React from 'react';
import { FunctionComponent, useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    font-family: sans-serif;
    --gap: 2rem;
    @media (max-width: 50rem) {
      --gap: 0.75rem;
    }
  }
  a {
    color: inherit;
    text-decoration: none;
  }

  
`

const useCss = (url: string) => {
  useEffect(() => {
    const link = document.createElement('link')
    link.href = url
    link.rel = 'stylesheet'
    document.head.appendChild(link)
    return () => {
      document.head.removeChild(link)
    }
  })
}

export const RootCss:FunctionComponent = () => {
  useCss('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap')
  useCss('https://unpkg.com/swiper@7/swiper-bundle.min.css')
  return <GlobalStyle />
}